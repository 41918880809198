<script>
import { ref, reactive } from 'vue';
import { useHarbourStore } from '@/stores/harbour-store';
import { useDownloadStore } from '@/stores/download-store.js';
import { useLinkObject } from '@/pages/Dashboard/composables/LinkObject'

export default {
  name: 'TitleRenderer',
  setup() {
    return {
      harbourStore: useHarbourStore(),
      downloadStore: useDownloadStore()
    };
  },
  data() {
    return {
      field: null,
      id: null,
      linkObject: null,
    };
  },
  created() {
    this.id = this.params.data.id;
    this.field = this.params.colDef.field;
    this.linkObject = useLinkObject(this.params.data);
    'update' in this.linkObject && this.linkObject.update();
  },
  beforeUnmount() {
    this.linkObject = null;
  },
  methods: {
    refresh() {
    },
    getOriginalFile() {
      const originalFileName = this.linkObject.originalFileName;
      const originalFileGcsPath = this.linkObject.gcsPath;
      const originalFileDisplayId = this.linkObject.originalFileDisplayId;
      this.downloadStore.fileSystemDownloadOriginal({ originalFileName, originalFileGcsPath, originalFileDisplayId });
    },
  },
};
</script>

<template>
  <div class="cell-container">
    <div class="link-title">
      <span>{{ linkObject.title || '-' }}</span>
    </div>
  </div>
</template>

<style scoped>
.link-title {
  font-weight: 400;
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
  margin-bottom: 2px;
  color: rgb(54, 54, 54);
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-font-smoothing: antialiased;
}
.agreementTitle {
  /* font-size: 1.1em; */
  color: #545454;
  margin-bottom: 14px;
  margin-left: 2px;
}
.linkDetailText,
.linkDetailTextLink {
  /* font-size: 11px; */
  font-weight: 400;
  line-height: 16.5px;
  color: rgb(102, 102, 102);
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
