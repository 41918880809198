<script>
import { useHarbourStore } from '@/stores/harbour-store';
import { useLibraryStore } from '@/stores/library-store';
import { useDownloadStore } from '@/stores/download-store';
import { mapState } from 'pinia'
import { ToastProgrammatic as Toast } from 'buefy';

export default {
  setup() {
    return {
      libraryStore: useLibraryStore(),
      harbourStore: useHarbourStore(),
      downloadStore: useDownloadStore(),
    }
  },
  data() {
    return {
      row: null,
      currentFolderCanEdit: false,
      isExpanded: false,
      overflowButton: {
        iconLeft: 'fa-ellipsis-v',
        options: [
          { icon: 'fa-copy', text: 'Copy' },
          { icon: 'fa-folder', text: 'Move' },
          { icon: 'fa-download', text: 'Download' }
        ]
      },

      name: null,
      id: null,
      isUploaded: null,
      isRendererReady: false,
    }
  },
  mounted() {
    this.row = this.params.data;
    this.id = this.row.id;
    this.name = this.row.name;
    this.isUploaded = true;
    this.init();  
  },
  computed: {
    ...mapState(useLibraryStore, ['hasEditPermissions']),
    ...mapState(useLibraryStore, ['gridApi']),
    downloadButtonDisabled() {
      // check for hidden columns if folder is view-only
      if (!this.gridApi) return true;
      const columns = this.gridApi.getColumnDefs();
      const itemsColumn = columns.find(column => column.field === 'items');
      const attachmentsColumn = columns.find(column => column.field === 'attachments');

      return Boolean(!this.hasEditPermissions && itemsColumn.hide && attachmentsColumn.hide)


      /*
      // hide download button if folder is view-only and items column is hidden
      const columns = libraryStore.gridApi?.getColumnDefs() || [];
      const itemsColumn = columns.find((column) => column.field === 'items');
      return Boolean(
        !hasEditPermissions.value &&
        itemsColumn?.hide
      );
      */
    },
    showDetails() {
      const context = this.row.context_source;
      return !!context && Object.keys(context).length ? true : false;
    }
  },
  methods: {
    async init() {
      await new Promise((resolve) => setTimeout(resolve, 10));
      this.isRendererReady = true;
    },
    expandDetails() {
      const id = this.row.id;
      this.libraryStore.expandDetails(id);
      this.isExpanded = !this.isExpanded;
    },
    async download() {
      const folder = this.harbourStore.getCurrentFolder;
      const folderName = folder.name;
      const parentAssetId = this.row.id;
      const fileName = this.row.file_name;

      let includeAssets = true;
      let includeAttachments = true;

      if (!this.harbourStore.isAdmin) {
        // set download options based on hidden columns and edit permissions
        const columns = this.gridApi.getColumnDefs();
        const itemsColumn = columns.find(column => column.field === 'items');
        const attachmentsColumn = columns.find(column => column.field === 'attachments');

        if (itemsColumn.hide) includeAssets = false;
        if (attachmentsColumn.hide) includeAttachments = false;
      }

      let totalDownloadCount = 0;
      if (includeAssets) totalDownloadCount += 1;
      if (includeAttachments) {
        const attachmentCount = this.params.data.attachments?.length || 0;
        totalDownloadCount += attachmentCount;
      }

      if (totalDownloadCount === 0) {
        Toast.open({
          duration: 2000,
          message: 'No files to download.',
          position: 'is-bottom',
          type: 'is-black',
        });
        return;
      };
      try {
        let downloadUrl = null;
        if(totalDownloadCount > 1) {
            const payload = {
                assetDisplayIds: [parentAssetId],
                rootFolderName: folderName,
                includeAssets,
                includeAttachments,
            }
            await this.downloadStore.filesystemZipDownload(totalDownloadCount, payload);
        } else {
            const payload = {
                assetDisplayId: parentAssetId,
                isDownload: true,
                exportFileName: fileName,
            }
            await this.downloadStore.fileSystemDownloadAssetOnly(payload);
        }
      } catch (err) {
        const suggest = "Please refresh and try again later or contact support@harbourshare.com";
        const issue = "Unable to create download at this time.";
        Toast.open({
            duration: 2500,
            message: `${issue} ${suggest}`,
            position: 'is-top',
            type: 'is-warning',
        });
      }
    },
    move() {
      if (!this.hasEditPermissions) return;
      this.libraryStore.moveAssets([this.params.data.id])
    },
    copy() {
      if (!this.hasEditPermissions) return;
      this.libraryStore.copyAssets([this.params.data.id])
    },
    deleteAsset() {
      if (!this.hasEditPermissions) return;

      const options = {
        id: this.params.data.id,
        name: this.params.data.name,
      }
      this.libraryStore.removeAsset(options)
    },
    assetIsDraft() {
      return this.libraryStore.assetIsDraft(this.row);
    },
    openCkeditorModalFromRow() {},
    rename() {
      if (!this.hasEditPermissions) return;
      this.libraryStore.promptEditDisplayName(this.id, this.name, this.isUploaded);
    },
  }
}
</script>

<template>
  <div class="cell" v-if="isRendererReady">
    <div class="actions-buttons buttons">
      <button
        title="Open agreement link editor"
        class="folders-button first-button"
        :disabled="!hasEditPermissions"
        @click="copy">
        <i class="fal fa-copy"></i>
        <span>Copy</span>
      </button>

      <button
        title="Move to new folder"
        class="folders-button"
        :disabled="!hasEditPermissions"
        @click="move">
        <i class="fal fa-folder-upload"></i>
        <span>Move</span>
      </button>

      <button
        title="Download file"
        class="folders-button"
        :disabled="downloadButtonDisabled"
        @click="download">
        <i class="fal fa-download"></i>
        <span>Download</span>
      </button>

      <b-dropdown
        position="is-bottom-left"
        animation=""
        append-to-body
        :triggers="['click']"
        aria-role="list"
        style="margin-left: 0px"
        class="dropdown">
        <button class="folders-dropdown" slot="trigger" icon-pack="fas" icon-right="caret-down">
          <b-icon pack="fas" icon="ellipsis-vertical"></b-icon>
        </button>
        <b-dropdown-item aria-role="listitem" @click="rename" :disabled="!hasEditPermissions"><i class="fal fa-pencil"></i><span class="menu-item">Rename item</span></b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="download" :disabled="downloadButtonDisabled"><i class="fal fa-download"></i><span class="menu-item">Download item</span></b-dropdown-item>
        <hr class="dropdown-divider">
        <b-dropdown-item aria-role="listitem" @click="deleteAsset" :disabled="!hasEditPermissions"><i class="fal fa-trash-alt"></i><span class="menu-item">Delete</span></b-dropdown-item>
      </b-dropdown>

    </div>
  </div>
</template>

<style scoped>
.menu-item {
  margin-left: 10px;
}
.dropdown {
  background-color: white;
}
.actions-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.actions-icon {
  border-radius: 3px;
  padding: 10px;
  margin-right: 5px;
  transition: all 250ms ease;
  cursor: pointer;
}
.actions-icon i {
  color: #667079;
  font-weight: 500;
  font-size: 18px;
}
.actions-icon:hover {
  background-color: #e8e8e8;
}
.details-row {
  color: var(--olympian-blue, #1A4C8B);
  margin-left: 10px;
  margin-top: 10px !important;
  /* Button Label */
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 125% */
  cursor: pointer;
  transition: all 250ms ease;
}
.details-row i {
  padding-right: 5px;
}
.details-row:hover {
  color: black;
}

.actions-buttons.buttons {
  display: flex;
  padding: 0;
  margin: 0;
  /* font-size: 0.8em; */
  width: 100%;
  overflow: hidden;
  white-space: nowrap !important;
}
button i {
  margin-right: 5px;
  padding: 0;
}

.folders-button {
  outline: none;
  font-size: 12px;
  border: 1px solid #dbdbdb;
  background-color: transparent;
  border-radius: 0;
  display: inline-block;
  padding: 10px !important;
  color: #6a6a6a;
  height: 32px !important;
  margin: 0 !important;
  width: auto !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-right: 0px;
  user-select: none;
  background-color:white;
}
.folders-button:hover {
  border-color: #b5b5b5;
  color: black;
}
.folders-button:active {
  border-color: #2e5a89;
}
.folders-button:disabled,
.folders-button[disabled] {
  color: rgb(178, 178, 178);
  cursor: not-allowed;
  opacity:.9;
}
.folders-button:disabled:hover,
.folders-button[disabled]:hover {
  border-top: 1px solid #dbdbdb !important;
  border-bottom: 1px solid #dbdbdb !important;
  border-left: 1px solid #dbdbdb !important;
}

.folders-dropdown {
  outline: none;
  font-size: 12px;
  border: 1px solid #dbdbdb;
  background-color: transparent;
  border-radius: 0 4px 4px 0;
  display: inline-block;
  padding: 10px !important;
  color: #6a6a6a;
  height: 32px !important;
  margin: 0 !important;
  width: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.folders-dropdown:hover {
  border-color: #b5b5b5;
  color: black;
}
.folders-dropdown:active {
  border-color: #2e5a89;
}
.first-button {
  border-radius: 4px 0 0 4px;
  border-right: 0px;
}
.last-button {
  border-radius: 0 4px 4px 0;
}
</style>
